import React, { useContext } from "react";
import SubMenuItem from "./SubMenuItem";
import {
  Container,
  ShadowDiv,
  ContentWrapper,
  SubMenuSection,
  MenuGroupWrap,
} from "./styled";
import MainContainerContext from "../../../context/MainContainer";

function SubMenu({ childItems, handleHovered, hoverIndex }) {
  const { getContainer } = useContext(MainContainerContext);
  const containerWidth = getContainer().offsetWidth;
  return (
    <Container
      onMouseEnter={() => {
        handleHovered(true, hoverIndex);
      }}
      onMouseLeave={() => {
        handleHovered(false, hoverIndex);
      }}
      containerWidth={containerWidth}
    >
      <ShadowDiv />
      <ContentWrapper>
        <SubMenuSection>
          <MenuGroupWrap>
            {childItems &&
              childItems.map((item, key) => (
                <SubMenuItem key={key} item={item} />
              ))}
          </MenuGroupWrap>
        </SubMenuSection>
      </ContentWrapper>
    </Container>
  );
}

export default SubMenu;