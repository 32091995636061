import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  @media screen and (max-width: 768px) {
    flex: 2;
  }
`;
export const Collection = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.spacing_sm};
`;

export const ItemText = styled.div`
  font-family: "Montserrat Medium";
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blackGreen};
`;

export const ImageWrap = styled.div`
position: relative;
width: 75px;
height: 60px;
display: inline-block;
padding: 0 45px;
`;
