import React from "react";
import Link from "next/link";
import { Container, TextRow, Wrap } from "./styled";

function PolicyAndTerms() {
  return (
    <Container>
      <TextRow>© {(new Date().getFullYear())} Classy Kitchen and Bath, All Rights Reserved</TextRow>
      <Wrap>
        <Link href="/privacy-policy">
          <a>
            <TextRow hovered={true}>Privacy Policy & Terms</TextRow>
          </a>
        </Link>
      </Wrap>
    </Container>
  );
}

export default PolicyAndTerms;
