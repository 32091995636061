import React from "react";
import SocialIconLink from "../../SocialIconLink";
import { Container, ImageWrap } from "./styled";
import socialLinks from "./_socialLinks";
import Image from "next/image";
import { IsMobile } from "../../ResponsiveContainers";

const SocialLinksSection = () => {
  return (
    <Container>
      {socialLinks &&
        socialLinks.map((item, key) => (
          <SocialIconLink
            key={key}
            imgSrc={item.imgSrc}
            hoverImgSrc={item.hoverImgSrc}
            href={item.href}
            pright="10px"
            label={item.label}
          />
        ))}
      <IsMobile>
        <ImageWrap as="a" href="https://www.nari.org/">
          <Image
            className="logo"
            layout="fill"
            objectFit="contain"
            src="/images/logo/NARI_GreaterPhoenix_Logo.png"
            alt="National association of the remodeling industry logo"
          />
        </ImageWrap>
        <ImageWrap as="a" href="https://nkba.org/">
          <Image
            className="logo"
            layout="fill"
            objectFit="contain"
            src="/images/logo/nkba-logo-primary.jpg"
            alt="National kitchen and bath association logo"
          />
        </ImageWrap>
      </IsMobile>
    </Container>
  );
};

export default SocialLinksSection;
