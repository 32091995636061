import styled from "styled-components";
import { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacings.spacing_s};
`;

const hoveredStyle = css`
  text-decoration-line: underline;
  text-decoration-color: ${({ theme }) => theme.colors.blackGreen};
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  transition: transform 0.5s ease-in-out;
`;

export const TextRow = styled.span`
  cursor: pointer;
  ${({ theme }) => theme.components.menuItem.text};
  ${({ type }) => type === "category" && `font-family: Montserrat`};
  ${({ type }) => type === "category" && `font-weight: Bold`};
  &:hover {
    ${({ type }) => type === "child" && hoveredStyle};
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
