import React from "react";

import NavItem from "../NavItem";
import SummaryTitle from "../SummaryTitle";
import { Container, Collection, ItemText, ImageWrap } from "./styled";
import {
  eventClickPhoneNumber,
  eventClickEmail,
} from "../../../services/analytics/ga4CustomEvents";
import Image from "next/image";

function ContactUs() {
  return (
    <Container>
      <SummaryTitle>Contact Us</SummaryTitle>
      <Collection>
        <ItemText>Showroom</ItemText>
        <ItemText>
          <NavItem
            href={
              "http://maps.google.com/?q=4320 W Chandler Blvd, Chandler, AZ 85226"
            }
            name={"4320 W Chandler Blvd, Chandler, AZ 85226"}
            aria-label="External link to google maps"
          />
        </ItemText>
      </Collection>
      <Collection>
        <ItemText data-calltrk-noswap
          onClick={() =>
            eventClickPhoneNumber(
              "18665802280",
              "Clicks on phone numbers in the Footer",
            )
          }
        >
          <NavItem name="480-933-0355" href="tel:14809330355" />
        </ItemText>
        <ItemText>Monday-Friday: 10am-5pm</ItemText>
        <ItemText>Saturday: 10am-2pm</ItemText>
        <ItemText>Sunday: Closed</ItemText>
      </Collection>
      <Collection>
        <ItemText
          onClick={() => eventClickEmail("KBAppointments@classycompanies.com")}
        >
          <NavItem
            href={"mailto:appointments@classycompanies.com"}
            name={"appointments@classycompanies.com"}
          />
        </ItemText>
      </Collection>
      <Collection>
        <ImageWrap as="a" href="https://www.nari.org/">
          <Image
            className="logo"
            layout="fill"
            objectFit="contain"
            src="/images/logo/NARI_GreaterPhoenix_Logo.png"
            alt="National association of the Remodeling industry logo"
          />
        </ImageWrap>
        <ImageWrap as="a" href="https://nkba.org/"><Image
          className="logo"
          layout="fill"
          objectFit="contain"
          src="/images/logo/nkba-logo-primary.jpg"
          alt="National Kitchen and bath association"
        />
        </ImageWrap>
      </Collection>
    </Container>
  );
}

export default ContactUs;
