import styled from "styled-components"

export const Container = styled.div``;
export const FooterMenuWrap = styled.div``;

export const MenuWrap = styled.div`
  display: flex;
  padding-top: 7px;
  flex-direction: column;
`;
