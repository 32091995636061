import styled from "styled-components";

export const Container = styled.div``;
export const MenuWrap = styled.div.attrs((props) => ({
  role: "navigation",
}))`
  display: flex;
  padding-top: 7px;
  gap: 1rem;
  @media only screen and (max-width: 560px) {
    flex-direction: column;
  }
`;
