import React from "react";
import { withTheme } from "styled-components";
import Link from "next/link";

import { LogoDefault } from "../Logos";
import Menu from "../Menu";
import {
  Container,
  LogoSection,
  ContentWrap,
  CallUsBtnWrap,
  Wrapper,
  CustomPButton,
} from "./styled";
import { eventClickPhoneNumber } from "../../services/analytics/ga4CustomEvents";

function Header({ theme }) {
  const handleClickCallUs = () => {
    const phoneNumber = "14809330355";
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrap>
          <LogoSection>
            <Link href={"/"}>
              <a role="button" aria-label="Classy Kitchens logo, life organized, home page">
                <LogoDefault />
              </a>
            </Link>
          </LogoSection>
          <Menu />
        </ContentWrap>
        {/* <CallUsBtnWrap>
          <CustomPButton
            aria-label={"call us"}
            tabIndex="0"
            ptype="primary"
            pname="Call us!"
            shadow="yes"
            hoverBgColor={theme.colors.prune}
            onClick={() => {
              handleClickCallUs();
              eventClickPhoneNumber("14809330355", "Clicks on phone numbers by CallUs Button");
            }}
          />
        </CallUsBtnWrap> */}
      </Wrapper>
    </Container>
  );
}

export default withTheme(Header);
