import React from "react";
import Link from "next/link";
import MenuItem from "../MenuItem";
import { Container, MenuWrap, NavFooter, TextElm } from "./styled";

const NavMenu = ({ expanded, menuLists }) => {
  return (
    <Container expanded={expanded}>
      <MenuWrap>
        {menuLists.map((item, index) =>
          item.type !== "parent" ? (
            <Link key={index} href={item.href || "/"}>
              <a>
                <MenuItem item={item} key={index}>
                  {item.title}
                </MenuItem>
              </a>
            </Link>
          ) : (
            <MenuItem
              key={index}
              item={item}
              menuIcon={<i className="fas fa-chevron-down" />}
            >
              {item.title}
            </MenuItem>
          ),
        )}
      </MenuWrap>
      <NavFooter>
        <TextElm>
          <Link href={"/franchise-opportunities"}>
            <a className="link">
           Franchise Opportunities
           </a> 
           </Link>

           <Link href={"/careers"}>
            <a className="link">
           | Careers
           </a> 
           </Link>
           
           </TextElm>
        <TextElm>4809330355</TextElm>
      </NavFooter>
    </Container>
  );
};

export default NavMenu;
