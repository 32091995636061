import styled from "styled-components";

export const Container = styled.div.attrs(props => ({
  // role: "contentinfo"
}))`
  width: 95%;
  max-width: ${({ theme }) => theme.layout.content.maxWidth}px;
  margin: 0 auto;
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#ffffff")};
  padding-left: ${({ theme }) => theme.spacings.spacing_big};
  padding-right: ${({ theme }) => theme.spacings.spacing_big};
  padding-top: ${({ theme }) => theme.spacings.spacing_xxl_m};

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media only screen and (max-width: 560px) {
    flex-direction: column;
    padding: 2px
      ${({ theme }) => theme.layout.content.paddingHorizontal.mobile}px;
  }
`;

export const FooterContentWrap = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacings.spacing_xss};
  padding-left: ${({ theme }) => theme.spacings.spacing_xss};
  padding-right: ${({ theme }) => theme.spacings.spacing_xss};
`;

export const Wrap = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex: unset;
    flex-direction: column;
    padding-top: 24px;
    padding-left: 15px;
    padding-right: 0px;
  }
  @media only screen and (max-width: 560px) {
    display: none;
  }
`;

export const LogoWrap = styled.div`
  display: block;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const DesktopFooter = styled.div`
  @media only screen and (max-width: 560px) {
    display: none;
  }
`;

export const MobileFooterWrap = styled.div`
  display: none;
  @media only screen and (max-width: 560px) {
    display: block;
  }
`;
