export const desktopMenus = [
  {
    key: "home",
    title: "Home",
    href: "/",
    type: "general",
  },

  {
    key: "products",
    title: "Products",
    href: "/view-all-products",
    type: "parent",
    children: [
      {
        key: "kitchens",
        title: "Kitchens",
        href: "/products/kitchens",
        type: "child",
      },
      {
        key: "baths",
        title: "Bath",
        href: "/products/baths",
        type: "child",
      },
      {
        key: "counters",
        title: "Countertops",
        href: "/products/countertops-page",
        type: "child",
      },
      {
        key: "viewAll",
        title: "View All",
        href: "/view-all-products",
        type: "child",
      },
      {
        key: "360-page",
        title: "360 Virtual Tours",
        href: "/360-page",
        type: "child",
      }

    ],
  },
  {
    key: "location",
    title: "Locations",
    href: "/locations",
    type: "general",
  },
  {
    key: "about",
    title: "About",
    href: "/why-choose-classy",
    type: "parent",
    children: [
      {
        key: "why_choose_us",
        title: "Why Choose Classy Kitchen & Bath",
        href: "/why-choose-classy",
        type: "child",
      },
      {
        key: "ourStory",
        title: "Our Story",
        href: "/our-story",
        type: "child",
      },
      {
        key: "faq",
        title: "FAQ",
        href: "/frequently-asked-questions",
        type: "child",
      },

      {
        key: "privacy",
        title: "Privacy Policy",
        href: "/privacy-policy",
        type: "child",
      },
    ],
  },
  // {
  //   key: "blog",
  //   title: "Blog",
  //   href: "/blogs/posts/1",
  //   type: "general",
  // },
  {
    key: "contact",
    title: "Contact Us",
    href: "/get-in-touch",
    type: "general",
  },
  {
    key: "financing",
    title: "Financing",
    href: "/landing/financing",
    type: "general",
    financing: true,
  },
];

export const desktopMenusOriginal = [
  {
    key: "home",
    title: "Home",
    href: "/",
    type: "general",
  },
  {
    key: "products",
    title: "Products",
    href: "/view-all-products",
    type: "parent",
    children: [
      {
        key: "kitchens",
        title: "Kitchens",
        href: "/products/kitchens",
        type: "child",
      },
      {
        key: "baths",
        title: "Bath",
        href: "/products/baths",
        type: "child",
      },


      {
        key: "counters",
        title: "Countertops",
        href: "/products/counters",
        type: "child",
      },

    ],
  },
  {
    key: "location",
    title: "Locations",
    href: "/locations",
    type: "general",
  },
  {
    key: "about",
    title: "About",
    href: "/why-choose-classy",
    type: "parent",
    children: [
      {
        key: "contact",
        title: "Contact Us",
        href: "/get-in-touch",
        type: "child",
      },
      {
        key: "faq",
        title: "FAQ",
        href: "/frequently-asked-questions",
        type: "child",
      },
      {
        key: "privacy",
        title: "Privacy Policy",
        href: "/privacy-policy",
        type: "child",
      },
      {
        key: "materials and finishes",
        title: "Materials & Finishes",
        href: "/materials-and-finishes",
        type: "child",
      },
    ],
  },
  {
    key: "blog",
    title: "Blog",
    href: "",
    type: "parent",
    children: [
      {
        key: "blog1",
        title: "Blog Section",
        href: "/",
        type: "category",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
    ],
  },
];

export const mobileFooterMenus = [
  {
    key: "why_choose_us",
    title: "Why Choose Us",
    href: "/why-choose-classy",
    type: "general",
  },
  {
    key: "locations",
    title: "Locations",
    href: "/locations",
    type: "general",
  },

  {
    key: "careers",
    title: "Careers",
    href: "/careers",
    type: "general",
  },
  {
    key: "accessibility",
    title: "Accessibility Statement",
    href: "/accessibility-statement",
    type: "general",
  },
];
