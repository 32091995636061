import React from "react";
import SocialLinksSection from "../SocialLinksSection";
import SummaryTitle from "../SummaryTitle";
import PolicyAndTerms from "../PolicyAndTerms";
import LogoSecondary from "../../Logos/LogoSecondary";
import { Container, SummaryWrap, SummaryContent, LogoWrap } from "./styled";

const SummaryAndSocial = () => {
  return (
    <Container>
      <LogoWrap>
        <LogoSecondary />
      </LogoWrap>
      <SummaryWrap>
        <SummaryTitle>Experience the difference</SummaryTitle>
        <SummaryContent>
          Our professional designers work with you to create individualized
          designs that fit your wants and needs. Your unique custom
          kitchen or bath solution design will be both beautiful and
          functional. With Classy, you don’t have to sacrifice form for
          practicality- you can have both! Choose between styles, materials,
          finishes, hardware, and accessories to create the perfect organized
          kitchen and bath storage solution for you.
        </SummaryContent>
      </SummaryWrap>
      <SocialLinksSection />
      <PolicyAndTerms />
    </Container>
  );
};

export default SummaryAndSocial;
