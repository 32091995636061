const arr = [
  {
    name: "Why Choose Classy Kitchen & Bath",
    href: "/why-choose-classy"
  },
  {
    name: "Locations",
    href: "/locations"
  },
  {
    name: "Franchise Opportunities",
    href: "/franchise-opportunities"
  },
  {
    name: "Careers",
    href: "/careers"
  },
  {
    name: "FAQ",
    href: "/frequently-asked-questions"
  },
  {
    name: "Employee Info",
    href: "https://live.goepower.com/Login.aspx"
  },
  {
    name: "Accessibility Statement",
    href: "/accessibility-statement"
  },
]

export default arr;
