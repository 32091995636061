import Image from "next/image";

function LogoSecondary() {
  return (
    <>
      <Image
        src="/images/logo/ClassyClosets-Secondary Black.png"
        alt="Classy Closets Logo"
        width={337}
        height={75}
        loading="lazy"
        quality={100}
      />
    </>
  );
}

export default LogoSecondary;
