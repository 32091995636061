import styled from "styled-components";

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacings.spacing_xxl_m};
  padding-bottom: ${({ theme }) => theme.spacings.spacing_xxl_m};
  display: flex;
  flex-direction: row;
`;

export const ImageWrap = styled.div`
position: relative;
width: 75px;
height: 60px;
display: inline-block;
padding: 0 45px;
`;
